import { useEffect, useState } from "react";
import Accordion from "./Accordion";
import Head from "next/head";

const FAQ = ({
  type,
  locationName,
  carName,
  lowestPrice,
}: {
  type: string;
  locationName?: string;
  carName?: string;
  lowestPrice?: string;
}) => {
  const [openFaq, setOpenFaq] = useState<number | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const generateDynamicFaqContent = (
    airportName?: string,
    carName?: string,
    lowestPrice?: string
  ) => {
    return [
      {
        id: 1,
        question: `What is the most popular car to rent in ${airportName}?`,
        ans: `The most popular car to rent in ${airportName} is ${carName} or similar`,
      },
      {
        id: 2,
        question: `Can I rent a car in ${airportName} with a debit card?`,
        ans: `Yes, you can rent a car in ${airportName} with a debit card`,
      },
      {
        id: 3,
        question: `How much is to rent a car from ${airportName}?`,
        ans: `Renting a car from ${airportName} with rent80 starts at ${lowestPrice}/day`,
      },
      {
        id: 4,
        question: `Does ${airportName} have a car rental on site?`,
        ans: `Yes, ${airportName} has a car rental on site`,
      },
    ];
  };

  let data =
    type === "airport"
      ? generateDynamicFaqContent(locationName, carName, lowestPrice)
      : faqData;

  const faqSchema = {
    "@context": "https://schema.org",
    name: "FAQ",
    "@type": "FAQPage",
    mainEntity: data.map(
      (faq: { id: number; question: string; ans: string }) => {
        return {
          "@type": "Question",
          name: "FAQ Question",
          text: faq.question,
          acceptedAnswer: {
            "@type": "Answer",
            name: "FAQ Answer",
            text: faq.ans,
          },
        };
      }
    ),
  };
  return (
    <section id="faq" className="bg-white px-[13px] py-[60px] my-[40px] ">
      <Head>
        {process.browser && (
          <script type="application/ld+json">
            {JSON.stringify(faqSchema)}
          </script>
        )}
      </Head>
      <div className="mx-auto max-w-7xl">
        <h2 className="font-bold text-base lg:text-[40px] mb-[22px] lg:mb-[40px] text-my-dark-blue">
          Frequently asked questions
        </h2>
        {data.map((faq: { id: number; question: string; ans: string }) => {
          return (
              <Accordion
                key={faq.id}
                faq={faq}
                setFaq={setOpenFaq}
                openFaq={openFaq}
              />
          );
        })}
      </div>
    </section>
  );
};

export default FAQ;

let faqData = [
  {
    id: 1,
    question: "What are the best days of the week to rent a car?",
    ans: "When it comes to renting a car, there is no one-size-fits-all solution for finding the best day to do so. For instance, at a tourist destination, the majority of flights arrive on Fridays or Saturdays, resulting in higher rental prices during those days. Conversely, in a busy city, renting a car on the weekend could be a good idea since fewer people may be interested in renting cars at that time, which can lead to lower prices. The key is to identify quieter days when you're more likely to get better discounts. If you require a rental car urgently, try to pick it up in the evening because most rental cars are usually picked up in the morning or afternoon. By doing so, you can save more money. Booking your rental car at least three months before your trip is another great way to save money. Lastly, it's crucial to arrive early at the rental counter to ensure a smooth rental process.",
  },
  {
    id: 2,
    question:
      "Which is cheaper: renting a car for a longer or shorter duration?",
    ans: "Renting a car for an extended period of time is often more cost-effective, as rental car companies generally offer lower rates for long-term rentals. Additionally, unlimited mileage is a common option that may be available with long-term rentals. Typically, traditional car rental periods range from one day to six months. When choosing a car, it's important to consider the size that suits your requirements. For groups of up to four people with normal luggage amounts, a standard car is a good option. On the other hand, for shorter trips with less baggage, like minimalist packing, an intermediate or midsize car may be more appropriate. Always keep these factors in mind when making your car rental booking.",
  },
  {
    id: 3,
    question: "How many miles are allowed on a rental car in a week?",
    ans: "When it comes to rental cars, there are no pre-programmed mileage limits, so you can drive as much as you want without any restrictions. It's also important to note that there are no limitations on using the standard mileage rate for leased vehicles. As long as you're willing to cover the repair costs, you can drive the rental car as much as you need. However, daily rentals often have mileage limits set by rental companies, ranging from 20 miles/30km to 250 miles/400km per day. This is why renting a car for a longer period of time is usually more beneficial. Many rental companies offer long-term rentals that can last up to two years, but the practical duration for renting a car is typically around a year. Keep in mind to monitor the odometer, as rental car companies usually retire their vehicles between 25,000 and 40,000 miles.",
  },
  {
    id: 4,
    question:
      "What are the long-term financial implications of buying versus renting a car?",
    ans: "One of the biggest long-term financial benefits of renting a car instead of buying one is lower monthly payments. When you rent a car, you don't have to spend a large portion of your salary on EMI payments. Other advantages of renting a car include zero maintenance costs, flexibility regarding the rental duration, and no fear of depreciation. This is why it's becoming more common to rent a car on a 24-month, 36-month, or 48-month contract, with an average rental duration of 36 months.",
  },
];
