import { useEffect, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi";

type Props = {
  faq: {
    id: number;
    question: string;
    ans: string;
  };
  setFaq: (number: any) => void;
  openFaq: number | null;
};

const Accordion = ({ faq: { id, question, ans }, setFaq, openFaq }: Props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (openFaq === id) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [openFaq]);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setFaq(id);
    setActive(!active);
  };

  return (
    <div>
      <div
        className={`bg-transparent border-b w-full cursor-pointer ${active}`}
        onClick={toggleAccordion}
      >
        <div className="flex flex-col items-start">
          <div className="flex w-full justify-between py-[19.5px] lg:py-6 text-left font-medium text-my-dark-blue  focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
            <h3>{question}</h3>
            <HiChevronDown
              className={`${
                active ? "rotate-180 transform duration-200" : "duration-200"
              } h-[20px] w-[20px] text-black`}
            />
          </div>
          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              e.stopPropagation()
            }
            ref={contentRef}
            className={
              active
                ? `overflow-hidden height-transition cursor-text`
                : `overflow-hidden`
            }
          >
            <p className="pb-4 text-sm text-gray-500">{ans}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
